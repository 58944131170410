.vegetable-list {
  list-style: none;
  padding: 0;
  margin: 0;
  border-top: 1px solid var(--color-border);
}

.vegetable-list__item {
  border-bottom: 1px solid var(--color-border);
  padding: 0.5em 0;
  font-size: 16px;
}

@media screen and (min-width: 650px) {
  .vegetable-list__item {
    font-size: 18px;
  }
}

@media screen and (min-width: 1000px) {
  .vegetable-list__item {
    font-size: 20px;
  }
}

.vegetable-list__headline {
  font-size: 12px;
  font-family: var(--font-bold);
}

.vegetable-list__icon-wrapper {
  width: 35px;
  height: 35px;
  margin-right: 0.4em;
}

@media screen and (min-width: 650px) {
  .vegetable-list__icon-wrapper {
    width: 40px;
    height: 40px;
  }
}

.vegetable-list__link {
  display: flex;
  align-items: center;
  text-decoration: none;
}

.vegetable-list__link:hover {
  color: var(--color-orange);
}

.vegetable-list__link--active {
  font-style: italic;
}
