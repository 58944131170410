.signal {
  content: " ";
  width: 0.5em;
  height: 0.5em;
  border-radius: 50%;
  background-image: radial-gradient(
    var(--color-green-half),
    var(--color-green)
  );
  display: inline-block;
  border: 1px solid var(--color-green);
  box-shadow: 0 0 5px 0 var(--color-frisch);
}

.signal--gelagert {
  border: 1px solid var(--color-gelagert);
  background-image: radial-gradient(
    var(--color-yellow-half),
    var(--color-gelagert)
  );
  box-shadow: 0 0 5px 0 var(--color-gelagert);
}

.signal--gap-left {
  margin-left: 0.4em;
}

.signal--gap-right {
  margin-right: 0.4em;
}

.signal--off {
  border: 1px solid #e5e5e5;
  background-image: none;
  box-shadow: none;
}
