.vegetable {
  display: grid;
  grid-template-columns:
    var(--grid-sides) 1fr
    var(--grid-sides);
  grid-template-areas: ". content .";
  margin-bottom: var(--spacer);
}

.vegetable__layout {
  display: grid;
  grid-area: content;
  grid-template-areas:
    "main"
    "aside";
  gap: var(--grid-gap);
}

.vegetable__main {
  grid-area: main;
}

.vegetable__aside {
  grid-area: aside;
}

@media screen and (min-width: 750px) {
  .vegetable__layout {
    grid-template-columns: 3fr minmax(25%, 1fr);
    grid-template-areas: "main aside";
  }
}
