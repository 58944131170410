.veggie-tiles {
  display: grid;
  grid-template-columns: var(--grid-sides) minmax(1px, 1400px) var(--grid-sides);
  grid-template-areas: ". content .";
  justify-content: center;
  margin-bottom: var(--spacer);
}

.veggie-tiles__inner {
  grid-area: content;
  display: grid;
  grid-template-columns: var(--grid);
  gap: var(--grid-gap);
  margin-bottom: var(--spacer);
}

.veggie-tiles__tile {
  grid-column: span 2;
}

@media (min-width: 1200px) {
  .veggie-tiles__tile {
    grid-column: span 3;
  }
}

.veggie-tiles__title {
  font-size: 13px;
  font-family: var(--font-bold);
  margin-bottom: 0.25em;
  position: relative;
}

@media (min-width: 800px) {
  .veggie-tiles__title {
    font-size: 20px;
  }
}

@media (min-width: 1200px) {
  .veggie-tiles__title {
    font-size: 28px;
  }
}

.veggie-tiles__dates {
  margin-top: 0;
  margin-bottom: 0.25em;
}

@media (min-width: 1200px) {
  .veggie-tiles__dates {
    font-size: 18px;
  }
}

.veggie-tiles__icon-wrapper {
  width: 75%;
  max-width: 300px;
  filter: drop-shadow(1px 1px 1px rgba(18, 53, 15, 0.2));
  transition: transform ease 150ms;
}

@media (min-width: 1000px) {
  .veggie-tiles__icon-wrapper {
    filter: drop-shadow(1px 1px 1px rgba(18, 53, 15, 0.4));
  }
}

@media (min-width: 1200px) {
  .veggie-tiles__icon-wrapper {
    width: 100%;
  }
}

.veggie-tiles__icon {
  width: 100%;
}

.veggie-tiles__icon-animation svg {
  transform: scale(0.001) rotate(-30deg);
  transform-origin: bottom;
}

.veggie-tiles__link {
  text-align: center;
  display: flex;
  align-items: center;
  flex-direction: column;
  text-decoration: none;
}

.veggie-tiles__link:hover .veggie-tiles__icon-wrapper {
  transform: scale(0.9);
}
