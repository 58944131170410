@import "./normalize.css";
@import "./fonts.css";
@import "./variables.css";
@import "./globals.css";
@import "./gsap-effects.css";

@import "./templates/home.css";
@import "./templates/vegetable.css";
@import "./snippets/calendar-table.css";
@import "./snippets/footer.css";
@import "./snippets/headline.css";
@import "./snippets/legendSeason.css";
@import "./snippets/navigation.css";
@import "./snippets/signal.css";
@import "./snippets/vegetable-availability.css";
@import "./snippets/vegetable-headline.css";
@import "./snippets/vegetable-icon.css";
@import "./snippets/vegetables-list.css";
@import "./snippets/vegetable-list.css";
@import "./snippets/veggie-tiles.css";
@import "./snippets/writer.css";
