.headline {
  display: grid;
  grid-template-columns: var(--grid-sides) minmax(1px, 1400px) var(--grid-sides);
  grid-template-areas: ". content .";
  justify-content: center;
  margin-bottom: var(--spacer);
}

.headline__inner {
  grid-area: content;
}

.headline__content {
  max-width: 230px;
  font-size: 24px;
  margin: 0;
}

@media (min-width: 800px) {
  .headline__content {
    max-width: 330px;
    font-size: 36px;
  }
}

@media (min-width: 1200px) {
  .headline__content {
    font-size: 58px;
    max-width: 520px;
  }
}

.headline__month {
  font-family: var(--font-bold);
}
