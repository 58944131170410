.vegetable-headline {
  font-size: 24px;
  margin: 0;
}

@media (min-width: 800px) {
  .vegetable-headline {
    font-size: 36px;
  }
}

@media (min-width: 1200px) {
  .vegetable-headline {
    font-size: 58px;
  }
}

.vegetable-headline__lat {
  font-size: 0.55em;
  line-height: 2.084375em;
  white-space: nowrap;
  font-style: italic;
}
