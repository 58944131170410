:root {
  /* color palette */
  --color-brown: #72200c;
  --color-brown-half: #72200c50;
  --color-red: #fe482c;
  --color-red-half: #fe482c50;
  --color-yellow: #f2cf3c;
  --color-yellow-25: rgba(242, 207, 60, 0.5);
  --color-yellow-half: #f2cf3c50;
  --color-orange: #f49521;
  --color-orange-25: rgba(244, 149, 33, 0.25);
  --color-orange-half: #f4952150;
  --color-dark-green: #586000;
  --color-dark-green-half: #58600050;
  --color-green: #92d264;
  --color-green-25: rgba(146, 210, 100, 0.5);
  --color-green-half: #92d26450;

  /* global color styles */
  --color-background: #fdfbef;
  --color-background-dark: #f6f3e7;
  --color-font: #393600;
  --color-font-hover: rgba(57, 54, 0, 0.7);
  --color-font-light: #d0d3c0;
  --color-border: #ebede1;
  --color-gelagert: var(--color-yellow);
  --color-frisch: var(--color-green);

  /* link colors */
  --color-link: var(--color-yellow);
  --color-focus: var(--color-orange);
  --color-hover: var(--color-green);
  --color-active: var(--color-dark-green);

  /* grid */
  --grid: repeat(4, minmax(0, 1fr));
  --grid-gap: 20px;
  --grid-sides: 4vw;
  --spacer: 8vw;
  --half-spacer: 4vw;

  /* font sizes */
  --default-font-size: 13px;
}

@media (min-width: 500px) {
  :root {
    --spacer: 40px;
    --grid: repeat(6, minmax(0, 1fr));
  }
}

@media (min-width: 850px) {
  :root {
    --grid-sides: 6vw;
    --grid-gap: 30px;
    --grid: repeat(8, minmax(0, 1fr));
  }
}

@media (min-width: 1200px) {
  :root {
    --grid-sides: 100px;
    --spacer: 100px;
    --half-spacer: 25px;
    --grid: repeat(12, minmax(0, 1fr));
    --grid-gap: 40px;
    --default-font-size: 16px;
  }
}
