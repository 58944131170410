.vegetable-availability {
  font-size: 16px;
  margin-top: var(--spacer);
}

@media screen and (min-width: 650px) {
  .vegetable-availability {
    font-size: 18px;
  }
}

@media screen and (min-width: 1000px) {
  .vegetable-availability {
    font-size: 22px;
  }
}
