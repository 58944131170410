.writer {
  display: grid;
  grid-template-columns: var(--grid-sides) minmax(1px, 1400px) var(--grid-sides);
  grid-template-areas: ". content .";
  justify-content: center;
  margin-bottom: var(--spacer);
}

.writer__inner {
  grid-area: content;
  font-size: 1.25em;
}

.writer__inner h2 {
  margin: 3em 0 1em;
}

.writer__inner h3 {
  margin: 1.5em 0 0.5em;
}

.writer__inner p {
  margin: 0 0 1em;
}

.writer__inner > *:first-child {
  margin-top: 0;
}
