body {
  background-color: var(--color-background);
  font-family: var(--font-regular);
  font-size: var(--default-font-size);
  color: var(--color-font);
  margin: 0;
  -webkit-font-smoothing: antialiased; /* Chrome, Safari */
  -moz-osx-font-smoothing: grayscale; /* Firefox */
}

a {
  transition: background-color 100ms ease, color 100ms ease;
  color: inherit;
}

* {
  font-weight: 400;
}

strong {
  font-family: var(--font-bold);
}
