.footer {
  display: grid;
  grid-template-columns: var(--grid-sides) minmax(1px, 1400px) var(--grid-sides);
  grid-template-areas: ". content .";
  justify-content: center;
}

.footer__nav {
  grid-area: content;
  font-size: 10px;
  margin-top: var(--spacer);
  border-top: 1px solid var(--color-border);
  padding: 1em 0 2em;
  text-align: center;
}

@media (min-width: 850px) {
  .footer__nav {
    font-size: 12px;
    text-align: left;
  }
}

.footer__list {
  padding: 0;
  margin: 0;
}

@media (min-width: 850px) {
  .footer__list {
    display: flex;
  }
}

.footer__list-item {
  list-style: none;
  margin-right: 2em;
  line-height: 2em;
}
