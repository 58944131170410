.legendSeason {
  margin-bottom: var(--half-spacer);
  display: grid;
  grid-template-columns: var(--grid-sides) minmax(1px, 1400px) var(--grid-sides);
  grid-template-areas: ". content .";
  justify-content: center;
}

.legendSeason__inner {
  grid-area: content;
}

.legendSeason__signal-wrapper {
  position: relative;
}

.legendSeason__signal-wrapper + .legendSeason__signal-wrapper {
  margin-left: 2.5em;
}
