@font-face {
  font-family: "Kameron Bold";
  src: url("../fonts/Kameron-Bold.eot");
  src: local("Kameron Bold"), local("Kameron-Bold"),
    url("../fonts/Kameron-Bold.eot?#iefix") format("embedded-opentype"),
    url("../fonts/Kameron-Bold.woff2") format("woff2"),
    url("../fonts/Kameron-Bold.woff") format("woff"),
    url("../fonts/Kameron-Bold.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Kameron";
  src: url("../fonts/Kameron.eot");
  src: local("Kameron"),
    url("../fonts/Kameron.eot?#iefix") format("embedded-opentype"),
    url("../fonts/Kameron.woff2") format("woff2"),
    url("../fonts/Kameron.woff") format("woff"),
    url("../fonts/Kameron.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

:root {
  /* styles */
  --font-bold: "Kameron Bold";
  --font-regular: "Kameron";

  /* size */
  --font-base-size: 1rem;
  --font-default-linehight: 1.5;

  /* scaling */
  --font-xs-400: 0.625;
  --font-s-400: 0.75;
  --font-m-400: 1;
  --font-l-400: 1;
  --font-xl-400: 1.25;
  --font-xxl-400: 2;
  --font-xxxl-400: 2.25;
  --font-xxxxl-400: 2.5;

  --font-xs-960: 0.75;
  --font-s-960: 1;
  --font-m-960: 1.25;
  --font-l-960: 1.25;
  --font-xl-960: 2;
  --font-xxl-960: 3.5;
  --font-xxxl-960: 4.25;
  --font-xxxxl-960: 5.5;

  --font-l-1200: 1.5;

  --font-xs: calc(var(--font-base-size) * var(--font-xs-400));
  --font-s: calc(var(--font-base-size) * var(--font-s-400));
  --font-m: calc(var(--font-base-size) * var(--font-m-400));
  --font-l: calc(var(--font-base-size) * var(--font-l-400));
  --font-xl: calc(var(--font-base-size) * var(--font-xl-400));
  --font-xxl: calc(var(--font-base-size) * var(--font-xxl-400));
  --font-xxxl: calc(var(--font-base-size) * var(--font-xxxl-400));
  --font-xxxxl: calc(var(--font-base-size) * var(--font-xxxxl-400));
  --min-viewport: 400;
  --max-viewport: 960;
}

@media screen and (min-width: 400px) {
  :root {
    --font-xs: calc(
      var(--font-xs-400) * 1rem +
        ((var(--font-xs-960) - var(--font-xs-400)) * 16) *
        (
          (100vw - var(--min-viewport) * 1px) /
            (var(--max-viewport) - var(--min-viewport))
        )
    );

    --font-s: calc(
      var(--font-s-400) * 1rem + ((var(--font-s-960) - var(--font-s-400)) * 16) *
        (
          (100vw - var(--min-viewport) * 1px) /
            (var(--max-viewport) - var(--min-viewport))
        )
    );

    --font-m: calc(
      var(--font-m-400) * 1rem + ((var(--font-m-960) - var(--font-m-400)) * 16) *
        (
          (100vw - var(--min-viewport) * 1px) /
            (var(--max-viewport) - var(--min-viewport))
        )
    );

    --font-l: calc(
      var(--font-l-400) * 1rem + ((var(--font-l-960) - var(--font-l-400)) * 16) *
        (
          (100vw - var(--min-viewport) * 1px) /
            (var(--max-viewport) - var(--min-viewport))
        )
    );

    --font-xl: calc(
      var(--font-xl-400) * 1rem +
        ((var(--font-xl-960) - var(--font-xl-400)) * 16) *
        (
          (100vw - var(--min-viewport) * 1px) /
            (var(--max-viewport) - var(--min-viewport))
        )
    );

    --font-xxl: calc(
      var(--font-xxl-400) * 1rem +
        ((var(--font-xxl-960) - var(--font-xxl-400)) * 16) *
        (
          (100vw - var(--min-viewport) * 1px) /
            (var(--max-viewport) - var(--min-viewport))
        )
    );

    --font-xxxl: calc(
      var(--font-xxxl-400) * 1rem +
        ((var(--font-xxxl-960) - var(--font-xxxl-400)) * 16) *
        (
          (100vw - var(--min-viewport) * 1px) /
            (var(--max-viewport) - var(--min-viewport))
        )
    );

    --font-xxxxl: calc(
      var(--font-xxxxl-400) * 1rem +
        ((var(--font-xxxxl-960) - var(--font-xxxxl-400)) * 16) *
        (
          (100vw - var(--min-viewport) * 1px) /
            (var(--max-viewport) - var(--min-viewport))
        )
    );
  }
}

@media screen and (min-width: 960px) {
  :root {
    /* scaling */
    --font-xs: calc(var(--font-base-size) * var(--font-xs-960));
    --font-s: calc(var(--font-base-size) * var(--font-s-960));
    --font-m: calc(var(--font-base-size) * var(--font-m-960));
    --font-l: calc(
      var(--font-l-960) * 1rem + ((var(--font-l-1200) - var(--font-l-960)) * 16) *
        ((100vw - 960 * 1px) / (1200 - 960))
    );
    --font-xl: calc(var(--font-base-size) * var(--font-xl-960));
    --font-xxl: calc(var(--font-base-size) * var(--font-xxl-960));
    --font-xxxl: calc(var(--font-base-size) * var(--font-xxxl-960));
    --font-xxxxl: calc(var(--font-base-size) * var(--font-xxxxl-960));
  }
}

@media screen and (min-width: 1200px) {
  :root {
    /* scaling */
    --font-l: calc(var(--font-base-size) * var(--font-l-1200));
  }
}
