.vegetables-list {
  display: grid;
  grid-template-columns: var(--grid-sides) minmax(1px, 1400px) var(--grid-sides);
  grid-template-areas: ". content .";
  justify-content: center;
  margin-bottom: var(--spacer);
}

.vegetables-list__list {
  grid-area: content;
  list-style: none;
  padding: 0;
  margin: 0;
  border-top: 1px solid var(--color-border);
}

.vegetables-list__list-item {
  border-bottom: 1px solid var(--color-border);
  font-size: 1.5em;
  padding: 0.5em 0;
}
