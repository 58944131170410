.navigation {
  margin-bottom: var(--spacer);
  display: grid;
  grid-template-columns: var(--grid-sides) minmax(1px, 1400px) var(--grid-sides);
  grid-template-areas: ". content .";
  justify-content: center;
}

.navigation__nav {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid var(--color-border);
  background-color: var(--color-background);
  grid-area: content;
}

.navigation__logo {
  font-family: var(--font-bold);
  padding: 1em 1.5em 1em 0;
  text-decoration: none;
  outline: none;
  display: inline-block;
  white-space: nowrap;
}

@media screen and (min-width: 650px) {
  .navigation__logo {
    padding-right: 2em;
  }
}

.navigation__logo:hover {
  color: inherit;
  text-decoration: none;
}

.navigation__list {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
}

.navigation__link {
  text-decoration: none;
  border-left: 1px solid var(--color-border);
  padding: 1em 1.5em;
  outline: none;
  color: var(--color-font);
  display: inline-block;
}

@media screen and (min-width: 650px) {
  .navigation__link {
    padding: 1em 2em;
  }
}

.navigation__link--isActive {
  text-decoration: none;
  color: var(--color-orange);
}

.navigation__link:hover {
  text-decoration: none;
  color: var(--color-orange);
}
