.vegetable-icon {
  width: 75%;
  max-width: 300px;
  filter: drop-shadow(1px 1px 1px rgba(18, 53, 15, 0.2));
}

@media (min-width: 1000px) {
  .vegetable-icon {
    filter: drop-shadow(1px 1px 1px rgba(18, 53, 15, 0.4));
  }
}

@media (min-width: 1200px) {
  .vegetable-icon {
    width: 100%;
  }
}
