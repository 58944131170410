.calendar-table {
  --calendar-table__span-border: 1px;
  display: grid;
  grid-template-columns: var(--grid-sides) minmax(1px, 1400px) var(--grid-sides);
  grid-template-areas: ". content .";
  justify-content: center;
  margin-bottom: var(--spacer);
}

.calendar-table__inner {
  grid-area: content;
  overflow-x: scroll;
  position: relative;
}

.calendar-table__table {
  border-collapse: collapse;
}

.calendar-table__column {
  background-color: var(--color-background);
  min-width: 25px;
  width: 100px;
  border-left: 1px solid var(--color-background);
}

.calendar-table__column:first-child {
  min-width: 50px;
}

.calendar-table__column--active {
  background-color: rgba(244, 149, 33, 0.08);
  border-left: 1px solid var(--color-orange);
  border-right: 1px solid var(--color-orange);
}

.calendar-table__cell {
  padding: 0.5em 1em;
  font-size: 11px;
  color: var(--color-orange);
  letter-spacing: 0.05em;
  text-align: left;
  position: relative;
}

.calendar-table__cell-month {
  transform: rotate(-45deg) translateX(-8px) translateY(8px);
  width: 25px;
}

.calendar-table__cell--header {
  height: 65px;
  border: 1px solid var(--color-background);
}

.calendar-table__column--header-active {
  background-color: var(--color-background);
}

.calendar-table__cell--vegetable {
  padding: 0.5em 0.5em 0.5em 3px;
  display: flex;
  align-items: center;
  color: var(--color-font);
  font-size: 14px;
  letter-spacing: 0;
  text-decoration: none;
}

@media screen and (min-width: 1000px) {
  .calendar-table__cell--vegetable {
    font-size: 16px;
  }
}

.calendar-table__cell--vegetable:hover {
  color: var(--color-orange);
}

.calendar-table__icon-wrapper {
  width: 35px;
  height: 35px;
}

@media screen and (min-width: 650px) {
  .calendar-table__icon-wrapper {
    width: 50px;
    height: 50px;
  }
}

.calendar-table__data,
.calendar-table__data--saison,
.calendar-table__data--stored {
  position: relative;
}

.calendar-table__data::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 6px;
  background-color: var(--color-orange-25);
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  transition: background-color 300ms ease;
}

.calendar-table__row:hover .calendar-table__data::before {
  background-color: var(--color-orange);
}

.calendar-table__data--saison::after,
.calendar-table__data--stored::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 50%;
  transform: translateY(-50%);
  left: 0;
}

.calendar-table__data--saison::after {
  background-color: var(--color-green-25);
  border-top: var(--calendar-table__span-border) solid var(--color-green);
  border-bottom: var(--calendar-table__span-border) solid var(--color-green);
}

.calendar-table__data--stored::after {
  background-color: var(--color-yellow-25);
  border-top: var(--calendar-table__span-border) solid var(--color-yellow);
  border-bottom: var(--calendar-table__span-border) solid var(--color-yellow);
}

.calendar-table__data--saison-rounded-left::after {
  border-left: var(--calendar-table__span-border) solid var(--color-green);
  border-top-left-radius: 100px;
  border-bottom-left-radius: 100px;
  width: calc(100% - var(--calendar-table__span-border));
}

.calendar-table__data--saison-rounded-right::after {
  border-right: var(--calendar-table__span-border) solid var(--color-green);
  border-top-right-radius: 100px;
  border-bottom-right-radius: 100px;
  width: calc(100% - var(--calendar-table__span-border));
}

.calendar-table__data--stored-rounded-left::after {
  border-left: var(--calendar-table__span-border) solid var(--color-yellow);
  border-top-left-radius: 100px;
  border-bottom-left-radius: 100px;
  width: calc(100% - var(--calendar-table__span-border));
}

.calendar-table__data--stored-rounded-right::after {
  border-right: var(--calendar-table__span-border) solid var(--color-yellow);
  border-top-right-radius: 100px;
  border-bottom-right-radius: 100px;
  width: calc(100% - var(--calendar-table__span-border));
}

.calendar-table__vegetable-name {
  display: none;
  margin-left: 0.5em;
  font-family: var(--font-bold);
}

@media screen and (min-width: 650px) {
  .calendar-table__vegetable-name {
    display: unset;
  }
}
